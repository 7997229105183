<template>
  <div>
    <div class="font-semibold text-xl mb-5 flex justify-between items-center">
      <div>
        {{ form.type === "outcome" ? "Уход" : "Приход" }}
        <span
          v-if="accountingData && accountingData.is_blacklist"
          class="text-gray-500 text-xs"
          >(Черный список)</span
        >
        <i v-if="loading" class="el-icon-loading"></i>
      </div>
      <div>
        <el-button
          @click="() => $router.back()"
          size="small"
          icon="el-icon-back"
          round
        >
          Назад
        </el-button>

        <!--        v-if="[1, 2, 5].includes(form.channel_id)"-->
        <el-button
          v-if="form.type === 'outcome' && accounting_id !== 'new'"
          @click="() => downloadWaybill(accounting_id)"
          size="small"
          plain
          round
          type="success"
          :icon="loadingWaybill ? 'el-icon-loading' : 'el-icon-printer'"
          title="Накладной"
          :disabled="loadingWaybill"
        ></el-button>

        <el-button
          v-if="accounting_id !== 'new' && accountingData && form.pre_order > 0"
          plain
          round
          type="primary"
          size="small"
          @click="arrived"
        >
          Прибыл
        </el-button>

        <el-button
          v-if="
            accounting_id !== 'new' &&
            form.channel_id === 1 &&
            accountingData &&
            !accountingData.is_blacklist
          "
          @click="addToBlacklist"
          plain
          round
          type="info"
          size="small"
        >
          В черный список
        </el-button>

        <el-button
          v-if="accounting_id !== 'new' && form.channel_id !== 1"
          plain
          round
          type="danger"
          size="small"
          @click="cancel"
          icon="el-icon-error"
        >
          Отменить
        </el-button>
      </div>
    </div>

    <el-row :gutter="20">
      <el-col :sm="24" :md="14" :lg="16">
        <el-form label-position="top">
          <div class="mb-5 rounded-md">
            <ValidationObserver v-slot="{ handleSubmit }">
              <template v-if="accounting_id === 'new'">
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Склад</p>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-select
                      v-model="form.warehouse_id"
                      filterable
                      class="w-full"
                    >
                      <el-option
                        v-for="wh in warehouses"
                        :key="wh.id"
                        :value="wh.id"
                        :label="wh.name"
                      ></el-option> </el-select
                    ><span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    ></ValidationProvider
                  >
                </div>
                <div v-if="form.type === 'outcome'" class="mb-3">
                  <p class="text-gray-500 mb-1">Канал продаж</p>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-select
                      v-model="form.channel_id"
                      filterable
                      class="w-full"
                    >
                      <el-option
                        v-for="ch in availableChannels"
                        :key="ch.id"
                        :value="ch.id"
                        :label="ch.name"
                      ></el-option> </el-select
                    ><span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    ></ValidationProvider
                  >
                </div>

                <div
                  v-if="
                    form.type === 'outcome' &&
                    [2, 5, 6].includes(form.channel_id)
                  "
                  class="mb-3"
                >
                  <p class="text-gray-500 mb-1">Процент банка</p>
                  <ValidationProvider rules="min:1" v-slot="{ errors }">
                    <el-input-number
                      v-model="form.bank_tax_percentage"
                    ></el-input-number>
                    <br />
                    <span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    ></ValidationProvider
                  >
                </div>

                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Номер документа</p>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-input v-model="form.basis"></el-input
                    ><span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    ></ValidationProvider
                  >
                </div>
                <div v-if="form.type === 'income'" class="mb-3">
                  <p class="text-gray-500 mb-1">Предзаказ(дней)</p>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-input
                      type="number"
                      v-model.number="form.pre_order"
                    ></el-input>
                    <span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>
                <div class="mb-3">
                  <p class="text-gray-500 mb-1">Тип оплаты</p>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <el-select
                      v-model="form.payment_type"
                      filterable
                      class="w-full"
                    >
                      <el-option
                        v-for="pt in payment_types"
                        :key="pt.id"
                        :value="pt.id"
                        :label="pt.name"
                      ></el-option>
                    </el-select>
                    <span
                      class="text-red-500"
                      v-if="errors && errors.length > 0"
                      >{{ errors[0] }}</span
                    >
                  </ValidationProvider>
                </div>

                <payments
                  v-if="form.payment_type === 1"
                  :payments="payments"
                  @add:payment="addPayment"
                  @delete:payment="deletePayment"
                ></payments>
              </template>
              <template v-else>
                <div>
                  <ul class="w-full border border-b-0 text-xs">
                    <template v-if="accountingData && form.type === 'outcome'">
                      <li
                        v-if="accountingData.order_state"
                        class="flex items-center border-b"
                      >
                        <span class="w-1/3 border-r p-3">Состояние</span>
                        <span class="p-3">{{
                          accountingData.order_state
                        }}</span>
                      </li>
                      <li
                        v-if="accountingData.order_status"
                        class="flex items-center border-b"
                      >
                        <span class="w-1/3 border-r p-3">Статус</span>
                        <span class="p-3">
                          {{ accountingData.order_status }}
                          <el-button
                            v-if="
                              accountingData.order_status === 'COMPLETED' ||
                              (accountingData.order_status === 'CANCELLED' &&
                                accountingData.order_state === 'SIGN_REQUIRED')
                            "
                            @click="checkAccountingStatus"
                            :disabled="loading"
                            size="small"
                            plain
                            type="primary"
                            round
                            >Проверить статус</el-button
                          >
                        </span>
                      </li>
                    </template>

                    <li
                      v-if="accountingData && accountingData.created_at2"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Дата создания</span>
                      <span class="p-3">{{
                        dateTimeFormat(accountingData.created_at2)
                      }}</span>
                    </li>
                    <li
                      v-if="accountingData && accountingData.updated_at"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Дата обновления</span>
                      <span class="p-3">{{
                        dateTimeFormat(accountingData.updated_at)
                      }}</span>
                    </li>

                    <li class="flex items-center border-b">
                      <span class="w-1/3 border-r p-3">Склад</span>
                      <span class="p-3">{{
                        getCatalogItemName(form.warehouse_id, "warehouses")
                      }}</span>
                    </li>

                    <li
                      v-if="form.channel_id"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Канал продаж</span>
                      <span class="p-3">{{
                        getCatalogItemName(form.channel_id, "channels")
                      }}</span>
                    </li>
                    <li class="flex items-center border-b">
                      <span class="w-1/3 border-r p-3">Номер документа</span>
                      <span class="p-3">{{ form.basis }}</span>
                    </li>
                    <li
                      v-if="form.pre_order"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Предзаказ(дней)</span>
                      <span class="p-3">{{ form.pre_order }}</span>
                    </li>
                    <li class="flex items-center border-b">
                      <span class="w-1/3 border-r p-3">Тип оплаты</span>
                      <span class="p-3">{{
                        getCatalogItemName(form.payment_type, "payment_types")
                      }}</span>
                    </li>
                    <li
                      v-if="accountingData && accountingData.total_price"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Сумма</span>
                      <span class="p-3">{{ accountingData.total_price }}</span>
                    </li>

                    <li
                      v-if="accountingData && accountingData.tax"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Комиссия</span>
                      <span class="p-3 flex">
                        {{ accountingData.tax }}
                      </span>
                    </li>

                    <li
                      v-if="accountingData && accountingData.order_state"
                      class="flex items-center border-b"
                    >
                      <span class="w-1/3 border-r p-3">Комиссия %</span>
                      <span class="p-3 flex">
                        <template v-if="[2].includes(form.channel_id)">
                          <el-input
                            v-model.number="tax"
                            size="mini"
                            type="number"
                            class="mr-1"
                          ></el-input>
                          <el-button @click="saveTax" size="mini" round
                            >Сохранить</el-button
                          >
                        </template>
                        <template v-else>{{ tax }}</template>
                      </span>
                    </li>
                  </ul>
                </div>
              </template>

              <template v-if="transactions && transactions.length > 0">
                <h2 class="mt-6 mb-3 text-xl flex justify-between items-center">
                  Транзакции
                </h2>

                <el-table :data="transactions" size="small" border>
                  <el-table-column type="index" label="N"></el-table-column>
                  <el-table-column prop="name" label="Счет"> </el-table-column>
                  <el-table-column prop="sum" label="Сумма"> </el-table-column>
                  <el-table-column prop="type" label="Тип"> </el-table-column>
                </el-table>
              </template>

              <h2 class="mt-6 mb-3 text-xl flex justify-between items-center">
                Товары
                <template v-if="accounting_id === 'new'">
                  <SearchCatalogProducts
                    v-if="form.type === 'income'"
                    @select:product="addProduct"
                  />
                  <SearchStorageProducts
                    v-else-if="form.type === 'outcome' && form.warehouse_id"
                    :warehouse-id="form.warehouse_id"
                    @select:product="addProduct"
                  />
                </template>
              </h2>
              <div v-if="accSumDiff > 0">
                <FillMissingProduct
                  :diff-sum="accSumDiff"
                  :warehouse-id="form.warehouse_id"
                  @fill="fillMissedProduct"
                />
              </div>
              <el-table :data="products" size="small" border>
                <el-table-column type="index" label="N"></el-table-column>
                <el-table-column prop="name" label="Название продукта">
                  <template v-slot="scope">
                    [<router-link
                      target="_blank"
                      class="text-blue-500"
                      :to="{ name: 'Product', params: { id: scope.row.id } }"
                      >{{ scope.row.id }}</router-link
                    >] - {{ scope.row.name }}</template
                  >
                </el-table-column>
                <el-table-column prop="dealer_price" label="Цена">
                  <template v-slot="scope">
                    <ValidationProvider
                      v-if="accounting_id === 'new'"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <el-input
                        type="number"
                        v-model.number="scope.row.dealer_price"
                        size="small"
                      ></el-input>
                      <span
                        class="text-red-500"
                        v-if="errors && errors.length > 0"
                        >{{ errors[0] }}</span
                      >
                    </ValidationProvider>
                    <template v-else>
                      {{ scope.row.dealer_price || scope.row.base_price }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column align="right" prop="count" label="Кол-во">
                  <template v-slot="scope">
                    <!--                    <el-button-->
                    <!--                      v-if="accounting_id === 'new'"-->
                    <!--                      @click="changeProductCount(scope.row.id, -1)"-->
                    <!--                      size="small"-->
                    <!--                      >-</el-button-->
                    <!--                    >-->
                    <el-input
                      v-model.number="scope.row.count"
                      type="number"
                      size="small"
                    ></el-input>
                    <!--                    <span class="mx-2">-->
                    <!--                      {{ scope.row.count || scope.row.quantity }}-->
                    <!--                    </span>-->
                    <!--                    <el-button-->
                    <!--                      v-if="accounting_id === 'new'"-->
                    <!--                      @click="changeProductCount(scope.row.id, 1)"-->
                    <!--                      size="small"-->
                    <!--                      >+</el-button-->
                    <!--                    >-->
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="accounting_id === 'new'"
                  align="right"
                  label="Действия"
                >
                  <template v-slot="scope">
                    <el-button
                      size="small"
                      type="danger"
                      plain
                      @click="deleteProduct(scope.row.id)"
                      icon="el-icon-delete"
                    ></el-button>
                  </template>
                </el-table-column>
                <!--            accountingData.order_state !== 'ARCHIVE' &&-->
                <el-table-column
                  v-if="
                    form.type === 'outcome' &&
                    accounting_id !== 'new' &&
                    form.warehouse_id &&
                    accountingData.accounting_status !== -1
                  "
                  label="Замена товара"
                >
                  <template v-slot="scope">
                    <SearchStorageProducts
                      :warehouse-id="form.warehouse_id"
                      @select:product="
                        (product) => replaceProduct(product, scope.row)
                      "
                    />
                  </template>
                </el-table-column>
              </el-table>

              <div class="mt-3 text-right">
                <el-button
                  v-if="accounting_id === 'new'"
                  plain
                  round
                  type="primary"
                  size="small"
                  @click="handleSubmit(save)"
                >
                  Сохранить
                </el-button>
              </div>
            </ValidationObserver>

            <el-collapse accordion>
              <el-collapse-item
                v-if="accounting_id !== 'new' && form.type === 'income'"
                title="Продажи"
                name="1"
              >
                <template>
                  <h2
                    class="mt-6 mb-3 text-xl flex justify-between items-center"
                  >
                    Продажи
                  </h2>
                  <el-table
                    border
                    size="small"
                    v-loading="loading"
                    :data="solded"
                    stripe
                    style="width: 100%"
                  >
                    <el-table-column
                      align="center"
                      type="index"
                      width="50"
                      label="N"
                    >
                    </el-table-column>
                    <el-table-column
                      align="center"
                      width="50"
                      label="ID"
                      prop="id"
                    >
                      <template slot-scope="scope">
                        <router-link
                          class="text-blue-500"
                          :to="{
                            name: 'accounting-item',
                            params: { id: scope.row.id },
                          }"
                        >
                          {{ scope.row.id }}
                        </router-link>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="warehouse_name"
                      label="Склад"
                    ></el-table-column>
                    <el-table-column prop="type" label="Тип">
                      <template v-slot="scope">
                        <el-tag
                          size="mini"
                          :type="getStateOfAccounting(scope.row).type"
                        >
                          {{ getStateOfAccounting(scope.row).label }}
                        </el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Создано">
                      <template v-slot="scope">
                        {{ dateTimeFormat(scope.row.created_at2) }}
                      </template>
                    </el-table-column>
                    <!--                <el-table-column prop="created_at" label="Дата обновления">-->
                    <!--                  <template v-slot="scope">-->
                    <!--                    {{-->
                    <!--                      scope.row.updated_at &&-->
                    <!--                      dateTimeFormat(scope.row.updated_at)-->
                    <!--                    }}-->
                    <!--                  </template>-->
                    <!--                </el-table-column>-->
                    <el-table-column
                      prop="basis"
                      label="Документ"
                    ></el-table-column>
                    <el-table-column
                      prop="total_price"
                      label="Сумма"
                    ></el-table-column>
                    <el-table-column prop="order_status" label="Статус">
                      <template slot-scope="scope">
                        {{ scope.row.order_state }} <br />
                        {{ scope.row.order_status }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-collapse-item>
              <el-collapse-item
                v-if="accounting_id !== 'new'"
                :title="`Факт ${
                  (form.type === 'outcome' &&
                    outcome_products.length === 0 &&
                    '(!)') ||
                  ''
                }`"
                name="2"
              >
                <template>
                  <h2
                    class="mt-6 mb-3 text-xl flex justify-between items-center"
                  >
                    Факт
                    <el-button
                      v-if="
                        form.type === 'outcome' &&
                        outcome_products.length < accountingData.products.length
                      "
                      size="small"
                      plain
                      round
                      type="primary"
                      @click="setProducts"
                      >Присвоить продукты</el-button
                    >
                  </h2>
                  <el-table :data="outcome_products" size="small" border>
                    <el-table-column type="index" label="N"></el-table-column>
                    <el-table-column prop="name" label="Название продукта">
                      <template v-slot="scope">
                        [<router-link
                          target="_blank"
                          class="text-blue-500"
                          :to="{
                            name: 'Product',
                            params: { id: scope.row.product_id },
                          }"
                          >{{ scope.row.product_id }}</router-link
                        >] - {{ scope.row.product_name }}</template
                      >
                    </el-table-column>
                    <el-table-column prop="dealer_price" label="Цена">
                      <template v-slot="scope">
                        <el-input
                          v-if="accounting_id === 'new'"
                          type="number"
                          v-model.number="scope.row.dealer_price"
                          size="small"
                        ></el-input>
                        <template v-else>
                          {{ scope.row.dealer_price || scope.row.base_price }}
                        </template>
                      </template>
                    </el-table-column>
                    <el-table-column
                      v-if="form.type === 'income'"
                      prop="count"
                      label="Кол-во"
                    ></el-table-column>
                    <el-table-column
                      v-if="form.type === 'outcome'"
                      width="300"
                      label="IMEI"
                    >
                      <template slot-scope="scope">
                        <div class="flex items-start">
                          <el-input
                            v-model="scope.row.imei"
                            type="textarea"
                            size="mini"
                            class="mr-1.5"
                          ></el-input>
                          <el-button
                            @click="
                              () => saveImei(scope.row.id, scope.row.imei)
                            "
                            size="mini"
                            round
                            icon="el-icon-check"
                          ></el-button>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-form>
      </el-col>
      <el-col v-if="false" :sm="24" :md="10" :lg="8">
        <div class="border border-solid">
          <order-reviews
            v-if="form && form.basis && accountingData"
            :order-code="form.basis"
            :products="accountingData.products"
          ></order-reviews>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { dateTimeFormat } from "@/helpers/formatter";
import {
  createAccounting,
  getAccounting,
  updateAccounting,
  getWarehouses,
  getPaymentTypes,
  getChannels,
  checkAccountingStatus,
  removeAccounting,
  replaceAccountingItems,
  getWarehouseMissingProducts,
  getAccountingWaybill,
} from "@/api/accounting";

import SearchCatalogProducts from "@/components/SearchCatalogProducts";
import SearchStorageProducts from "@/components/SearchStorageProducts";
import payments from "@/views/Accounting/payments";
import FillMissingProduct from "@/components/FillMissingProduct.vue";
import { saveStorageImei } from "@/api/storage";
import OrderReviews from "@/views/Accounting/OrderReviews.vue";

export default {
  name: "AccountingItem",
  components: {
    OrderReviews,
    SearchCatalogProducts,
    SearchStorageProducts,
    payments,
    FillMissingProduct,
  },
  data: () => ({
    tax: 0,
    accountingData: null,
    form: {
      warehouse_id: null,
      type: "income",
      basis: null,
      channel_id: null,
      bank_tax_percentage: 0,
      payment_type: null,
      total: 0,
      pre_order: null,
    },

    payments: [],
    warehouses: [],
    products: [],
    payment_types: [],
    channels: [],
    outcome_products: [],
    solded: [],
    transactions: [],

    loading: false,
    loadingWaybill: false,
  }),
  computed: {
    accounting_id() {
      return this.$route.params.id;
    },
    user() {
      return this.$store.state.user;
    },
    availableChannels() {
      return this.channels.filter((ch) => ch.id !== 1);
    },
    accSumDiff() {
      if (this.form.type === "income") return false;
      if (this.form.channel_id !== 1) return false;
      if (!this.accountingData) return false;

      const products = this.accountingData.products;
      const productsSum = products.reduce(
        (acc, product) => acc + product.totalPrice,
        0
      );
      return this.accountingData.total_price - productsSum;
    },
  },
  async created() {
    if (this.$route.query.type === "outcome") {
      this.form.type = "outcome";
    }

    this.warehouses = await getWarehouses();
    this.payment_types = await getPaymentTypes();
    this.channels = await getChannels();

    if (this.accounting_id !== "new") {
      this.loading = true;
      const serverData = await getAccounting(this.accounting_id);
      const {
        warehouse_id,
        type,
        basis,
        payment_type,
        pre_order,
        total,
        channel_id,
        ...other
      } = serverData.accounting;
      this.products = serverData.products;
      this.solded = serverData.solded;
      this.outcome_products = serverData.outcome_storage;
      this.transactions = serverData.transactions;

      this.form = {
        warehouse_id,
        type,
        basis,
        payment_type,
        total,
        pre_order,
        channel_id,
      };
      this.tax = other.tax_percentage || 0;
      this.accountingData = other;
      console.log(other);
      this.loading = false;
    }
  },
  watch: {
    "form.warehouse_id": async function (val) {
      if (val && this.accounting_id === "new" && this.form.type === "income") {
        await this.getWarehouseMissingProducts();
      }
    },
    "form.channel_id": function (channel_id) {
      if (![2, 5].includes(channel_id)) {
        this.form.bank_tax_percentage = 0;
      }
    },
  },
  methods: {
    openWhatsapp(basis) {
      const text = `https://kaspi.kz/shop/review/productreview?orderCode=${basis}&productCode=yyyyyyyy&started_by=shop_order_details&rating=5`;
      window.open(
        "https://wa.me/77077110304?text=" + encodeURI(text),
        "_blank"
      );
    },
    async saveImei(storageId, imei) {
      console.log(storageId, imei);
      await saveStorageImei(storageId, imei);
    },
    async saveTax() {
      try {
        this.loading = true;
        const result = await updateAccounting(this.accounting_id, {
          action: "set_tax",
          tax: this.tax,
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
    addPayment({ account_id, sum }) {
      this.payments.push({
        account_id,
        sum,
      });
    },
    deletePayment({ account_id, sum }) {
      const payments = [];
      for (let i = 0; i < this.payments.length; i++) {
        if (
          this.payments[i].account_id === account_id &&
          this.payments[i].sum === sum
        ) {
          continue;
        }
        payments.push(this.payments[i]);
      }
      this.payments = payments;
    },
    async getWarehouseMissingProducts() {
      const products = await getWarehouseMissingProducts(
        this.form.warehouse_id
      );

      this.products = [];
      for (const id in products) {
        this.products.push(products[id]);
      }
    },
    changeProductCount(id, count) {
      const product = this.products.find((p) => p.id === id);
      product.count += count;
      if (product.count === 0) {
        this.deleteProduct(id);
      }
    },
    async handleMagicButton() {
      this.loading = true;
      const res = await replaceAccountingItems(this.accounting_id);
      console.log(res);
      this.loading = false;
    },
    async checkAccountingStatus() {
      this.loading = true;
      const res = await checkAccountingStatus(this.accounting_id);
      window.location.reload();
      console.log(res);
      this.loading = false;
    },
    async removeAccounting() {
      this.loading = true;
      const res = await removeAccounting(this.accounting_id);
      if (res.success) {
        this.$router.push({ name: "accounting" });
      } else {
        this.$message.error(
          `Есть признак продажи ${res.sold_quantity} товаров. Отмените уходы с ID ${res.outcome_ids}`
        );
      }
      console.log(res);
      this.loading = false;
    },
    async save() {
      const productsCount = this.products.reduce(
        (previousValue, product) => previousValue + product.count,
        0
      );
      const productsSum = this.products.reduce(
        (previousValue, product) =>
          previousValue + product.count * product.dealer_price,
        0
      );

      const paymentsSum = this.payments.reduce(
        (previousValue, payment) => previousValue + payment.sum,
        0
      );

      if (this.form.payment_type === 1 && paymentsSum !== productsSum) {
        this.$message.error("Не совпадает суммы товаров и платежей");
        return;
      }

      this.$confirm(
        `Количество товаров: ${productsCount} <br/> Сумма товаров: ${productsSum}. <br/> Продолжить?`,
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        await this.createAccounting();
      });
    },

    async arrived() {
      this.$confirm("Прибыли ли все товары? Продолжить?", "Внимание", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(async () => {
        await this.updateAccounting();
      });
    },

    async setProducts() {
      this.$confirm(
        "Присвоится товары если на складе есть. Продолжить?",
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      ).then(async () => {
        try {
          this.loading = true;
          const result = await updateAccounting(this.accounting_id, {
            action: "set_products",
          });
          if (result.success) {
            this.$message.success("Успешно");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            this.$message.error(`Ошибка! На складе нет этого товара`);
          }
        } catch (e) {
          this.$message.error(`Ошибка ${e.message}`);
        }
        this.loading = false;
      });
    },

    async cancel() {
      this.$confirm(
        "Вы действительно хотите отменить уход? Продолжить?",
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      ).then(async () => {
        await this.removeAccounting();
      });
    },

    async createAccounting() {
      try {
        this.loading = true;
        const result = await createAccounting({
          ...this.form,
          products: this.products.map((p) => ({
            id: p.id,
            name: p.name,
            count: p.count,
            dealer_price: p.dealer_price,
          })),
          payments: this.payments,
        });
        if (result.success) {
          this.$message.success("Успешно");
          await this.$router.push({ name: "accounting" });
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    async updateAccounting() {
      try {
        this.loading = true;
        const result = await updateAccounting(this.accounting_id, {
          action: "arrived",
        });
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },

    dateTimeFormat(timestamp) {
      return dateTimeFormat(timestamp * 1000);
    },

    addProduct(product) {
      console.log(product);
      const checkProductForAvailability = this.products.find(
        (p) => p.id === product.id
      );
      if (checkProductForAvailability) {
        checkProductForAvailability.count += 1;
      } else {
        this.products.push({
          ...product,
          count: 1,
          price: product.dealer_price || 0,
        });
      }
    },

    replaceProduct(newProduct, oldProduct) {
      this.$confirm(
        `Вы действительно хотите заменить <b>${oldProduct.name}</b> на <b>${newProduct.name}</b>?`,
        "Внимание",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
          dangerouslyUseHTMLString: true,
        }
      ).then(async () => {
        try {
          this.loading = true;
          const result = await updateAccounting(this.accounting_id, {
            action: "replace_product",
            old_product_id: oldProduct.id,
            product_id: newProduct.id,
            product_name: newProduct.name,
          });
          if (result.success) {
            this.$message.success("Успешно");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            this.$message.error(`Ошибка ${result.message}`);
          }
        } catch (e) {
          this.$message.error(`Ошибка ${e.message}`);
        }
        this.loading = false;
        console.log(newProduct, oldProduct);
      });
    },

    deleteProduct(productId) {
      console.log(productId);
      this.products = this.products.filter((p) => p.id !== productId);
    },

    getCatalogItemName(id, catalogName) {
      const catalogItem = this[catalogName].find((w) => w.id === id);
      return (catalogItem && catalogItem.name) || id;
    },

    async fillMissedProduct(product) {
      const result = await updateAccounting(this.accounting_id, {
        action: "fill_missed_product",
        product: product,
      });

      console.log("result", result);
    },

    async downloadWaybill(accountingId) {
      this.loadingWaybill = true;
      const res = await getAccountingWaybill(accountingId);
      this.loadingWaybill = false;
      if (!res.success) return;
      window.open(`https://api.alasal.kz/waybills/${res.waybill}`);
    },

    getStateOfAccounting(acc) {
      if (acc.type === "income") {
        return {
          label: "Приход",
          type: "primary",
        };
      }
      if (acc.accounting_status === -1) {
        return {
          label: "Отменен",
          type: "danger",
        };
      }
      if (acc.channel_id === 1) {
        if (acc.order_state === "ARCHIVE") {
          if (acc.order_status === "COMPLETED")
            return {
              label: "Уход",
              type: "success",
            };
          if (acc.order_status === "CANCELLED")
            return {
              label: "Отменен",
              type: "danger",
            };
        }
        return {
          label: "Бронь",
          type: "warning",
        };
      }
      return {
        label: "Уход",
        type: "success",
      };
    },
    async addToBlacklist() {
      await updateAccounting(this.accounting_id, {
        action: "blacklist",
      });

      this.$message.success("Успешно");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
  },
};
</script>

<style scoped></style>
